/****************************************************************************/
/*  Ваш script.js со всеми исправлениями                                    */
/****************************************************************************/
MK(document).ready(function () {

    MK(".lang button").click(function () {
        if (!MK(this).hasClass("active")) {
            let lang = MK(this).text();
            let url = MK(this).attr("data-url");
            if (lang && url) {
                MK.setCookie("lang", lang);
                setTimeout(() => {
                    window.location.href = url;
                }, 100);
            }
        }
    });

    setTimeout(() => {
        MK(".first-lines").addClass("show");
    }, 1000);

    MK(".portfolio-slide").click(function () {
        let data = MK(this).html();
        if(data) {
            MK(".popup picture").html(data);
            MK(".popup picture source").each(function () {
                let dataIMG = MK(this).attr("data-big");
                if(dataIMG) MK(this).attr("srcset", dataIMG);
            });
            let dataIMG = MK(".popup picture img").attr("data-big");
            if(dataIMG) MK(this).attr("src", dataIMG);
        }
        setTimeout( () => {
            MK("html, body").css("overflow", "hidden");
            MK(".popup").addClass("open");
        }, 200);
    });

    MK(".popup-close").click(function () {
        MK("html, body").css("overflow", "");
        MK(".popup").removeClass("open");
    });

    MK(".five-form input, .five-form textarea").input(function () {
        let name = MK("input.name").val();
        let email = MK("input.email").val();
        let message = MK("textarea.message").val();

        // Регулярное выражение для проверки email
        let emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
        let isEmailValid = emailPattern.test(email);

        if (name && message && isEmailValid) {
            MK("input.send").attr("disabled", "");
        } else {
            MK("input.send").attr("disabled", "disabled");
        }
    });

    MK("input.send").click(function () {
        MK(this).attr("disabled", "disabled");
        let formData = new FormData();
        formData.append("lang", MK(".lang button.active").text());
        formData.append("name", MK("input.name").val());
        formData.append("mail", MK("input.email").val());
        formData.append("message", MK("textarea.message").val());

        MK.ajax({
            method: "POST",
            url: "/admin/ajax/send.php",
            data: formData,
            success: function (response) {
                if (response == 200) MK(".answer").addClass("show");
                MK(this).attr("disabled", "");
            },
        });

    });

    if (window.innerWidth > 600) {

        progressLetters(".second-about--title");
        progressLetters(".second-about");
        progressLetters(".third-works--title");

        // Добавляем класс "h2" для букв заголовка
        MK(".second-about--title span, .third-works--title span").each(function () {
            MK(this).addClass("h2");
        });

        function handleScroll(elementSelector, onScrollEvent) {
            // Находим элемент, который анимируем
            const element = document.querySelector(elementSelector);
            if (!element) return;

            // Селектор родителя берём из атрибута scrolling-by
            const parentSelector = element.getAttribute('scrolling-by');
            if (!parentSelector) return;

            // Находим родителя
            const parent = document.querySelector(parentSelector);
            if (!parent) return;

            // Обработчик скролла
            window.addEventListener('scroll', () => {
                // Позиция родителя относительно документа
                const parentRect = parent.getBoundingClientRect();
                const parentTop = parentRect.top + window.pageYOffset; // пиксели от верха документа
                const parentHeight = parent.offsetHeight;

                // Текущий скролл и высота окна
                const windowScroll = window.pageYOffset;
                const windowHeight = window.innerHeight;

                // Точка, когда родитель «въезжает» в окно (его верх у нижней границы окна)
                const startScroll = parentTop - windowHeight;
                // Точка, когда родитель «уезжает» вверх (нижняя граница родителя пересекла верх окна)
                const endScroll = parentTop + parentHeight;

                let scrollPercent = 0;

                // Если ещё не доскроллили до родителя — 0%
                if (windowScroll < startScroll) {
                    scrollPercent = 0;

                    // Если проскроллили мимо родителя целиком — 100%
                } else if (windowScroll > endScroll) {
                    scrollPercent = 100;
                } else {
                    scrollPercent = ((windowScroll - startScroll) / (endScroll - startScroll)) * 100;
                }

                // Ограничим в пределах 0..100
                scrollPercent = Math.max(0, Math.min(scrollPercent, 100));

                // Вызываем пользовательскую логику
                onScrollEvent({
                    element,
                    parent,
                    scrollPercent
                });
            });
        }

        function updateScrollPosition(scrollPercent, element, baseValue, minLimit, start, end, onScrollEvent) {
            // 1. Считаем "долю" от 0 до 1
            let fraction = (scrollPercent - start) / (end - start);

            // 2. Ограничиваем fraction, чтобы она не выходила за [0..1]
            fraction = Math.max(0, Math.min(fraction, 1));

            // 3. Линейно интерполируем:
            //    baseValue при fraction=0
            //    minLimit   при fraction=1
            const calculatedValue = baseValue - fraction * (baseValue - minLimit);

            // 4. Вызываем коллбэк (всегда)
            onScrollEvent(calculatedValue, element);
        }


        /* ---------- Блок .GT-40--driving ---------- */
        handleScroll('.GT-40--driving', ({element, parent, scrollPercent}) => {
            updateScrollPosition(
                scrollPercent, element,
                /* baseValue= */ -40,
                /* minLimit= */ 153,
                /* start= */ 5,
                /* end= */ 45,
                (calculatedValue, el) => {
                    MK(el).css("top", `${calculatedValue}vh`);
                }
            );

            updateScrollPosition(
                scrollPercent, element,
                /* baseValue= */ 0,
                /* minLimit= */ 90,
                /* start= */ 40,
                /* end= */ 45,
                (calculatedValue, el) => {
                    MK(el).css("transform", `rotate(${-Math.abs(calculatedValue)}deg)`);
                }
            );

            updateScrollPosition(
                scrollPercent, element,
                /* baseValue= */ 25,
                /* minLimit= */ 220,
                /* start= */ 43,
                /* end= */ 80,
                (calculatedValue, el) => {
                    MK(el).css("left", `${calculatedValue}rem`);
                }
            );

        });

        /* ---------- Блок .first-my ---------- */
        handleScroll('.first-my', ({element, parent, scrollPercent}) => {
            updateScrollPosition(
                scrollPercent, element,
                /* baseValue= */ 30,
                /* minLimit= */ -70,
                /* start= */ 80,
                /* end= */ 100,
                (calculatedValue, el) => {
                    MK(el).css("top", `${calculatedValue}vh`);
                }
            );

        });

        /* ---------- Блок .first-info ---------- */
        handleScroll('.first-info', ({element, parent, scrollPercent}) => {
            updateScrollPosition(
                scrollPercent, element,
                /* baseValue= */ 70,
                /* minLimit= */ -30,
                /* start= */ 80,
                /* end= */ 100,
                (calculatedValue, el) => {
                    MK(el).css("top", `${calculatedValue}vh`);
                }
            );

        });


        /* ---------- Блок .experience ---------- */
        handleScroll('.experience', ({element, parent, scrollPercent}) => {
            // Анимация "числа" (например, стажа)
            updateScrollPosition(
                scrollPercent, element,
                /* baseValue= */ 0,
                /* minLimit= */ 12,
                /* start= */ 5,
                /* end= */ 35,
                (calculatedValue, el) => {
                    let val = Math.round(calculatedValue);
                    if(val === 12) MK(el).addClass("filled");
                    if(!MK(el).hasClass("filled")) {
                        if (val > 12) val = 12;
                        if (val < 10) val = "0" + val;
                        MK(el).children(".count").text(`${val}`);
                    }
                    else  MK(el).children(".count").text("12");
                }
            );

            // Анимация ширины .second-lines
            updateScrollPosition(
                scrollPercent, element,
                /* baseValue= */ 0,
                /* minLimit= */ 100,
                /* start= */ 35,
                /* end= */ 45,
                (calculatedValue, el) => {
                    MK(el).children(".second-lines").css("width", `${calculatedValue}vw`);
                }
            );

            // Анимация top
            updateScrollPosition(
                scrollPercent, element,
                /* baseValue= */ 0,
                /* minLimit= */ 60,
                /* start= */ 45,
                /* end= */ 55,
                (calculatedValue, el) => {
                    MK(el).css("top", `${-Math.abs(calculatedValue)}rem`);
                }
            );
        });

        /* ---------- Блок .about-my ---------- */
        handleScroll('.about-my', ({element, parent, scrollPercent}) => {
            // Появление букв заголовка
            const titleLetters = MK(".second-about--title span").length;
            updateScrollPosition(
                scrollPercent, element,
                0, // baseValue
                titleLetters, // minLimit
                55, // start
                70, // end
                (calculatedValue, el) => {
                    MK(".second-about--title span").each(function (index) {
                        if ((index - 1) >= calculatedValue) {
                            MK(this).removeClass("show-letter");
                        } else {
                            MK(this).addClass("show-letter");
                        }
                    });
                }
            );

            // Появление букв основного текста
            const textLetters = MK(".second-about span, .second-about br").length;
            updateScrollPosition(
                scrollPercent, element,
                0, // baseValue
                (textLetters * 5.5), // minLimit
                65, // start
                100, // end
                (calculatedValue, el) => {
                    MK(".second-about span").each(function (index) {
                        if ((index - 1) >= calculatedValue) {
                            MK(this).removeClass("show-letter");
                        } else {
                            MK(this).addClass("show-letter");
                        }
                    });
                }
            );

            // Смещение по top
            updateScrollPosition(
                scrollPercent, element,
                70,  // baseValue
                5,   // minLimit
                /* start= */ 60,
                /* end= */ 70,
                (calculatedValue, el) => {
                    MK(el).css("top", `${calculatedValue}rem`);
                }
            );


            // Смещение по top
            updateScrollPosition(
                scrollPercent, element,
                5,  // baseValue
                -100,   // minLimit
                /* start= */ 90,
                /* end= */ 100,
                (calculatedValue, el) => {
                    MK(el).css("top", `${calculatedValue}rem`);
                }
            );
        });

        /* ---------- Блок .third-works--title ---------- */
        handleScroll('.third-works--title', ({element, parent, scrollPercent}) => {
            // Появление букв заголовка
            const titleLetters = MK(".third-works--title span").length;
            updateScrollPosition(
                scrollPercent, element,
                0, // baseValue
                titleLetters, // minLimit
                10, // start
                20, // end
                (calculatedValue, el) => {
                    MK(".third-works--title span").each(function (index) {
                        if ((index - 1) >= calculatedValue) {
                            MK(this).removeClass("show-letter");
                        } else {
                            MK(this).addClass("show-letter");
                        }
                    });
                }
            );

            // Смещение по top
            updateScrollPosition(
                scrollPercent, element,
                10,  // baseValue
                -30,   // minLimit
                /* start= */ 90,
                /* end= */ 100,
                (calculatedValue, el) => {
                    MK(el).css("top", `${calculatedValue}rem`);
                }
            );
        });


        /* ---------- Блок .third-animation  ---------- */
        handleScroll('.third-animation', ({element, parent, scrollPercent}) => {

            updateScrollPosition(
                scrollPercent, element,
                /* baseValue= */ 40,
                /* minLimit= */ -15,
                /* start= */ 10,
                /* end= */ 30,
                (calculatedValue, el) => {
                    MK(el).children(".portfolio-animation").css("transform", `translateX(${calculatedValue}vw)`);
                }
            );
            updateScrollPosition(
                scrollPercent, element,
                /* baseValue= */ 0,
                /* minLimit= */ 5400,
                /* start= */ 10,
                /* end= */ 100,
                (calculatedValue, el) => {
                    MK(el).children(".portfolio-animation .gt-40-front img").css("transform", `rotate(${-Math.abs(calculatedValue)}deg)`);
                    MK(el).children(".portfolio-animation .gt-40-back img").css("transform", `rotate(${-Math.abs(calculatedValue)}deg)`);
                }
            );

            updateScrollPosition(
                scrollPercent, element,
                /* baseValue= */ 70,
                /* minLimit= */ -220,
                /* start= */ 30,
                /* end= */ 90,
                (calculatedValue, el) => {
                    MK(el).css("transform", `translateX(${calculatedValue}vw)`);
                }
            );
        });
    }
    else  {
// Наблюдатель для анимации счетчика
        const countObserver = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    const countElement = document.querySelector('.experience-counter .count');
                    if (countElement) {
                        countObserver.unobserve(entry.target);
                        setTimeout(() => { animateCount(countElement, 12); }, 500);

                    }
                }
            });
        }, { threshold: 0.1 });

        const countTarget = document.querySelector('.experience-counter');
        if (countObserver) {
            countObserver.observe(countTarget); // Начинаем отслеживание элемента для анимации
        }

        const Slider1 = new MKSlider({
            selectorSlider: '.portfolio-slider',
            selectorWrapper: '.portfolio-slider .portfolio-slider--wrapper',
            selectorPrev: '.portfolio-slider--nav .portfolio-nav--prev',
            selectorNext: '.portfolio-slider--nav .portfolio-nav--next',
            step: 1,
            start: 1,
            loop: true,
            autoPlay: false,
            speed: 5000,
            media: {
                768: {view: 3},
                1024: {view: 5}
            },
            onAfterMove: function () {
                setTimeout( () => {
                    MK(".portfolio-slide").removeClass("open");
                    MK(".portfolio-slide.active").addClass("open");
                }, 100);
            }
        });
    }
});

function progressLetters(selector) {
    const section = document.querySelector(selector);
    if (!section) {
        console.warn(`Элемент с селектором "${selector}" не найден.`);
        return;
    }
    const nodes = Array.from(section.childNodes);
    section.textContent = ''; // Очищаем содержимое элемента

    nodes.forEach(node => {
        // Если это текст, разбиваем на символы
        if (node.nodeType === Node.TEXT_NODE) {
            const chars = Array.from(node.textContent);
            chars.forEach(char => {
                const span = document.createElement('span');
                span.textContent = char;
                section.appendChild(span);
            });
        } else {
            // Если это элемент (например, <br>), просто клонируем
            section.appendChild(node.cloneNode(true));
        }
    });
}
function animateCount(element, target, initial = 0, delayFactor = 50) {
    let current = initial;
    const stepTime = delayFactor;
    const increment = () => {
        if (current <= target) {
            element.textContent = current.toString().padStart(2, '0');
            current++;
            if (current <= target) {
                setTimeout(increment, stepTime * (current - initial));
            } else {
                MK('.second-lines').addClass("show");
            }
        }
    };
    increment();
}